
    var riot = require('riot')
    
riot.tag2('linechart', '<canvas name="canv" height="{opts.height || 400}" width="{opts.width || 400}"></canvas>', 'linechart{display:block;}', '', function(opts) {
var Chart;

Chart = require('chart.js');

this.chart = null;

this.on('mount', function() {
  var ctx;
  Chart.defaults.global.responsive = true;
  ctx = this.canv.getContext("2d");
  this.gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
  this.gradientStroke.addColorStop(0, "#c0a454");
  this.gradientStroke.addColorStop(0.6, "#f2eead");
  this.gradientStroke.addColorStop(1, "#c0a454");
  return this.chart = new Chart(ctx, {
    type: opts.type,
    data: opts.data,
    options: opts.options
  });
});

this.on('update', (function(_this) {
  return function() {
    if (_this.chart) {
      _this.chart.data.datasets.length = opts.data.datasets.length;
      opts.data.datasets.forEach(function(dataset, idx) {
        if (typeof dataset.backgroundColor === "string" && dataset.backgroundColor === "GoldenRod") {
          dataset.backgroundColor = _this.gradientStroke;
        }
        if (typeof dataset.backgroundColor === "object" && Object.keys(dataset.backgroundColor).length > 0 && dataset.backgroundColor.indexOf('GoldenRod') > -1) {
          dataset.backgroundColor[dataset.backgroundColor.indexOf("GoldenRod")] = _this.gradientStroke;
        }
        return _this.chart.data.datasets[idx] = dataset;
      });
      return _this.chart.update();
    }
  };
})(this));
});
    
  