let isEditMode = window.location.search.match(/editmode=true/)
let componentCss = ""
document.querySelectorAll("link").forEach( tag => {
  let url = tag.getAttribute("href")
  if(url.startsWith("/resources/css/site")){
    componentCss = url
  }
})

import {component} from "duo-kit";
import CookieBanner from "./components/cookie-banner.svelte"
component({component:CookieBanner,tagname:"cookie-banner",href:componentCss,shadow:isEditMode})

var amob, backtotop, mmutationObserver, mo, riot;

window.riot = riot = require('riot');

require('riot-kit/lib/slideshow');
require("riot-kit/lib/img_loader");
require('riot-kit/lib/form');
require('riot-kit/lib/twitter');
require('riot-kit/lib/modal');
require('riot-kit/lib/form');
require('riot-kit/lib/ecommerce');
require('riot-kit/lib/pagination');
require('riot-kit/lib/dateformat');
require('riot-kit/lib/booking-calendar');
require('riot-kit/lib/dropdown');
require('riot-kit/lib/gmap');
require('riot-kit/lib/auth/forgot-password');
require('smoothscroll-polyfill').polyfill();
require('./tags/loginbar.tag');
require('./tags/ecommercebar.tag');
require('./tags/navigation.tag');
require('./tags/mydetails.tag');
require('./tags/mypassword.tag');
require('./tags/signup.tag');
require('./tags/tags-input.tag');
require('./tags/comments_input.tag');
require('./tags/comments_output.tag');
require('./tags/recaptcha.tag');
require('./tags/pricetag.tag');
require('./libs/slideshow.coffee');
require('./tags/linechart.tag');
require('./tags/thermometer.tag');

riot.mount('*');

document.addEventListener('duocms:updated', function() {
  return riot.mount('slideshow,gmap');
});

backtotop = document.querySelector('.backtotop');

if (backtotop) {
  backtotop.addEventListener('click', (function(_this) {
    return function(e) {
      e.preventDefault();
      return document.querySelector('#top').scrollIntoView({
        behavior: 'smooth'
      });
    };
  })(this));
}

mmutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

mo = new mmutationObserver(function(mutations) {
  var m, mutation, results;
  m = 0;
  results = [];
  while (m < mutations.length) {
    mutation = mutations[m];
    if (mutation.type === "attributes" && mutation.target.className.match(/navshowing/)) {
      [].slice.call(document.querySelectorAll(".navbar-nav li a")).forEach(function(subnav) {
        return subnav.addEventListener('click', function(e) {
          var subsecs;
          if (subnav.getAttribute('riot-tag') === "dropdown" && subnav.className.match(/open/)) {
            e.preventDefault();
            subnav.querySelector('a,i').addEventListener('click', function(e) {
              return window.location.href = subnav.querySelector('a').href;
            });
            subsecs = subnav.querySelectorAll('ul a');
            if (subsecs.length > 0) {
              return [].map.call(subsecs, (function(_this) {
                return function(sec) {
                  return sec.addEventListener('click', function(e) {
                    return window.location.href = sec.href;
                  });
                };
              })(this));
            }
          }
        });
      });
    }
    results.push(m++);
  }
  return results;
});

mo.observe(document.querySelector('body'), {
  attributes: true
});

amob = document.querySelector('.activate-mobile button');

if (amob) {
  amob.addEventListener('click', (function(_this) {
    return function(e) {
      var sb;
      e.preventDefault();
      sb = document.querySelector('.searchbar');
      return sb.classList.toggle('active');
    };
  })(this));
}

let qs = new URLSearchParams(window.location.search)

if(qs.has("from")){
  let from = qs.get("from")
  let categoryMap = {
    "/services/statutory-and-inspection-test/portable-appliance-testing-pat/":"PAT/003",
    "/services/statutory-and-inspection-test/eicr-inspections-and-certification/":"EIN/004",
    "/services/statutory-and-inspection-test/thermographic-surveys/":"EIN/004",
    "/services/statutory-and-inspection-test/fire-alarm-testing/":"FIR/008",
    "/services/statutory-and-inspection-test/fire-extinguisher-testing/":"FIR/008",
    "/services/statutory-and-inspection-test/fire-risk-assessments/":"FIR/008",
    "/services/statutory-and-inspection-test/emergency-lighting-testing/":"FIR/008",
    "/services/statutory-and-inspection-test/air-conditioning-heat-pump-leaks/":"GAS/005",
    "/services/electrical-maintenance-services/electrical-remedial-services/":"EIN/004",
    "/services/electrical-maintenance-services/lighting-maintenance/":"EIN/004",
    "/services/electrical-maintenance-services/reactive-maintenance/":"EIN/004",
    "/services/electrical-maintenance-services/electrical-vehicle-charging-installation/":"EIN/004",
    "/services/mechanical-maintenance-services/heating-ventilation-air-conditioning-ppm/":"GAS/005",
    "/services/mechanical-maintenance-services/reactive-maintenance/":"GAS/005",
    "/services/fire-and-security-maintenance-and-projects/fire_detection_alarm/":"FIR/008",
    "/services/fire-and-security-maintenance-and-projects/intruder_and_or_peri/":"FIR/008",
    "/services/fire-and-security-maintenance-and-projects/cctv_analogue_ip_/":"FIR/008",
    "/services/fire-and-security-maintenance-and-projects/access_control_systems/":"FIR/008",
    "/services/fire-and-security-maintenance-and-projects/ip_security_integration/":"FIR/008",
    "/services/project-services/mechanical-installation/":"GAS/005",
    "/services/project-services/electrical_installations/":"EIN/004"
  }
  let category = categoryMap[from]
  if(category && document.querySelector("form select[name=category]")){
    document.querySelector("form select[name=category]").value = category
  }

}

